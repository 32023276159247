import React from 'react';
import styled from '@emotion/styled';
// import core components
import withLocation from 'components/hocs/withLocation';
import { LinkButton, ImageBanner } from 'components';
import { Layout, Container } from 'layouts';
import AlogliaSearch from 'components/pages/search';
import { queryHelper } from 'helpers';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const StyledBackgroundImage = styled.div`
  display: flex;

  margin-bottom: 45px;
  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: 0px 0px 15px rgba(0, 22, 79, 0.1);
  overflow: hidden;

  @media all and (max-width: ${props => props.theme.breakpoints.l}) {
    margin: 0 20px 20px;
  }
`;


const SearchPage = props => {
  const {
    data: { topBanner },
    search,
  } = props;
  return (
    <Layout title="Search">
      <Container>
          {/*<a href={topBanner.field_url} target="_blank">
            <StyledBackgroundImage>
              <GatsbyImage image={getImage(queryHelper.getGatsbyImageData(topBanner))} alt=""/>
            </StyledBackgroundImage>
          </a>*/}
        <LinkButton text="Back to the Home" link="/" />
        <AlogliaSearch keyword={search.keyword} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query MySearchPageQuery {
    topBanner: nodeBanner(title: { eq: "categoryTopBanner" }) {
      drupal_id
      title
      field_display_title
      field_description
      field_url
      relationships {
        field_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [WEBP]
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
`;

export default withLocation(SearchPage);
